import React, { useState, useEffect } from 'react'

import {
    List,
    Popup,
    Button,
    Icon,
    Label
} from 'semantic-ui-react'

import { sendMessage } from '../../Utils'
import { socket } from '../../Services/Socket'
import { useToasts } from 'react-toast-notifications'

type ParticipantListItemProps = {
    participant: any
    setFlash: any
}

const ParticipantListItem = ({ participant, setFlash } : ParticipantListItemProps) => {
    const { clientId: id, id: databaseId, online, nickname: name, isRequestWord, isCoaching } = participant

    const [isActive, setIsActive] = useState(isRequestWord || isCoaching)
    const { addToast } = useToasts()
    const dispatchMessageIO = (message: any) => {
        sendMessage(socket, message, null, addToast);
    }

    useEffect(() => {
        if(!online){
            setIsActive(false)
        }
        if(isActive){ setIsActive(true) }
        if(!isActive){ setIsActive(false) }
        
    }, [online, isActive])
    
    const requestCoaching = () => {
        setIsActive(true)
        
        dispatchMessageIO({
            event: 'requestWordAssign',
            userId: databaseId,
            roomId: 1,
            socketId: id
        })
        participant.isRequestWord = false
    }

    const stop = () => {
        // Stop interaction
        setIsActive(false)

        dispatchMessageIO({
            event: 'requestWordRelease',
            userId: databaseId,
            socketId: id
        })
    }


    return <List.Item>
        <List.Content floated='left'>
            <Label circular color={online ? 'green' : 'red'} empty /> {name}
        </List.Content>
        <List.Content floated='right'>
            {isActive &&
                <Popup position='left center' size='tiny' inverted content='Detener' trigger={
                    <Button onClick={stop} icon size='mini' color='red'><Icon name='remove user' /></Button>
                } />
            }
            <Popup position='left center' size='tiny' inverted content='Dar la Palabra' trigger={
                <Button onClick={requestCoaching} icon size='mini' disabled={!online || isActive || !isRequestWord} color={!online ? 'black' : isRequestWord ? 'green' : 'grey'}><Icon name='hand point up outline' /></Button>
            } />
            <Popup position='left center' size='tiny' inverted content={`Pasar adelante a ${name.split(' ')[0]}`} trigger={
                <Button onClick={requestCoaching} icon size='mini' disabled={!online || isActive} color={!online ? 'black' : isActive ? 'green' : 'grey'}><Icon name='balance' /></Button>
            } />
        </List.Content>
    </List.Item>
}

export default ParticipantListItem