import { BasicMessage } from "./types"

const sendMessage = (socket: SocketIOClient.Socket, message: BasicMessage, setLoading: any, addToast: any) => {
    const preloaded = !!setLoading
    // DeployedConsole.log(`Enviando mensaje`, message)

    const { disconnected } = socket.emit('message', message);
    if (disconnected) {
        preloaded && setLoading(false)
        addToast('No hay conexión establecida con el servidor, por favor intente más tarde.', { appearance: 'error', autoDismiss: false })
    }
    return disconnected
}

export default sendMessage