import React, { useState } from 'react';

import { PlatformProfile } from "../../Utils/types"

import {
  Segment,
  List
} from 'semantic-ui-react'

import ProfileItem from './ProfileItem';

type ProfileSelectorProps = {
    options: PlatformProfile[]
    backToTheLogin(): void
    dispatchMessageIO(message: any): void
    profileLogged: any
    addToast: any
}

const ProfileSelector = ({ options, backToTheLogin, dispatchMessageIO, profileLogged, addToast }: ProfileSelectorProps) => {
    const [selected, setSelected] = useState(-1)

    return <Segment stacked>
        <List inverted animated selection>
            {options.map((option, index) =>
                <ProfileItem profile={option} key={`ProfileItem--${index}`} index={index} selected={selected} setSelected={setSelected} dispatchMessageIO={dispatchMessageIO} profileLogged={profileLogged} addToast={addToast} />
            )}
            <List.Item onClick={backToTheLogin}>
            <List.Icon name='caret square left outline' size='large' verticalAlign='middle'  />
            <List.Content as='a'>
                <List.Header>Volver a la opción anterior...</List.Header>
                <List.Description>Podrás ingresar nuevamente tu usuario.</List.Description>
            </List.Content>
            </List.Item>
        </List>
    </Segment>
}

export default ProfileSelector