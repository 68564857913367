import React, { Dispatch, SetStateAction } from 'react'
import { ProfileType } from '../Types'

let profile: ProfileType
let webcam: any
let screen: any
let setProfile: Dispatch<SetStateAction<any>>
let setWebcam: Dispatch<SetStateAction<any>>
let setScreen: Dispatch<SetStateAction<any>>
// @ts-ignore
const ProfileContext = React.createContext({ profile, setProfile, streams: { webcam, setWebcam, screen, setScreen } })

export const ProfileProvider = ProfileContext.Provider
export default ProfileContext