import React, { useRef, useEffect, useContext } from 'react'

import UserModel from '../../Models/User'
import { UserOV } from '../../Library/RoomContext/types'
import ProfileContext from '../../Library/ProfileContext'


type ParticipantVideoProps = {
    participant: UserModel | UserOV
    type?: 'principal' | 'secondary'
    className?: string
    mute?: boolean
}

const ParticipantVideo = ({ className, participant, type, mute }: ParticipantVideoProps) => {
    const videoRef = useRef(null)

    const { profile } = useContext(ProfileContext)

    const socketId = participant.refSocketId
    // const isReady = participant && participant.streamManager
    const isMe = socketId === profile.socketId
    const isParticipant = participant.rol === 'participante'

    // const [flash,setFlash] = useState(false)

    // Default values
    type = type || 'principal'

    useEffect(() => {
        participant.streamManager.addVideoElement(videoRef.current)
    // eslint-disable-next-line no-sparse-arrays
    }, [, participant.streamManager])

    const prefix = isMe ? `Yo${!isParticipant ? ` (${participant.rol})` : ''}` : `${participant.rol?.charAt(0).toUpperCase()}${participant.rol?.slice(1)}`
    return <div className={className}>
        <div id={`frameOf${socketId}`}>
            {true && <video
                autoPlay={true}
                playsInline={true}
                muted={mute}
                id={'video-' + participant.refSocketId}
                ref={videoRef}
            />}
        </div>
        <span className="ParticipantVideo--Name"><strong>{prefix}</strong>{!isMe ? `: ${participant.nickname}` : ''}</span>
    </div>
}

export default ParticipantVideo
