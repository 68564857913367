import React from "react";
import { UserOV } from "./types";
// import { useToasts } from "react-toast-notifications";

const LocalUser = () => {
    // const { addToast } = useToasts()
    
    const [connectionId, setHookConnectionId] = React.useState('');
    const [audioActive, setHookAudioActive] = React.useState(false);
    const [videoActive, setHookVideoActive] = React.useState(true);
    const [screenShareActive, setHookScreenShareActive] = React.useState(false);
    const [nickname, setHookNickname] = React.useState('');
    const [streamManager, setHookStreamManager] = React.useState(null);
    const [type, setHookType] = React.useState('local');
    const [rol, setHookRol] = React.useState('');
    const [refSocketId, setHookRefSocketId] = React.useState('');

    const isAudioActive = () => { return audioActive; }
    const isVideoActive = () => { return videoActive; }
    const isScreenShareActive = () => { return screenShareActive; }
    const getConnectionId = () => { return connectionId; }
    const getNickname = () => { return nickname; }
    const getStreamManager = () => { return streamManager; }
    const isLocal = () => { return type === 'local'; }
    const isRemote = () => { return !isLocal(); }
    const setAudioActive = (recisAudioActive: boolean) => {
        setHookAudioActive(recisAudioActive)
        // @ts-ignore
        getStreamManager()?.publishAudio(recisAudioActive);
        if (recisAudioActive) {
            // addToast('Se ha deshabilitado el audio.', { appearance: 'info', autoDismiss: true })
        } else {
            // addToast('Se ha habilitado el audio.', { appearance: 'info', autoDismiss: true })
        }
    }
    const setVideoActive = (recisVideoActive: boolean) => {
        setHookVideoActive(recisVideoActive)
        // @ts-ignore
        getStreamManager()?.publishVideo(recisVideoActive);
        if (recisVideoActive) {
            // addToast('Se ha deshabilitado el video.', { appearance: 'info', autoDismiss: true })
        } else {
            // addToast('Se ha habilitado el video.', { appearance: 'info', autoDismiss: true })
        }
    }
    const setScreenShareActive = (recisScreenShareActive: boolean) => { setHookScreenShareActive(recisScreenShareActive) }
    const setStreamManager = (recstreamManager: any) => { setHookStreamManager(recstreamManager) }
    const setConnectionId = (recconecctionId: string) => { setHookConnectionId(recconecctionId) }
    const setNickname = (recnickname: string) => { setHookNickname(recnickname) }
    const setType = (rectype: string) => { if (rectype === 'local' || rectype === 'remote') { setHookType(rectype) } }
    const setRol = (recrol: string) => { setHookRol(recrol) }
    const setRefSocketId = (refSocketId: string) => { setHookRefSocketId(refSocketId) }

    const localUser: UserOV = {
        connectionId,
        audioActive,
        videoActive,
        screenShareActive,
        nickname,
        streamManager,
        type,
        rol,
        refSocketId,
        isAudioActive,
        isVideoActive,
        isScreenShareActive,
        getConnectionId,
        getNickname,
        getStreamManager,
        isLocal,
        isRemote,
        setAudioActive,
        setVideoActive,
        setScreenShareActive,
        setStreamManager,
        setConnectionId,
        setNickname,
        setType,
        setRol,
        setRefSocketId
    }

    return localUser
}

export default LocalUser