// @ts-nocheck
import React from 'react'

import { IconFrameProps } from '../../Components/EventFrame/IconFrame';
import { Icon } from 'semantic-ui-react';

const Menu: IconFrameProps[] = [
    {
        icon: <Icon size='large' color='white' name='stop circle outline' />,
        text: 'Detener Interacciones',
        doEvent: 'stopAddonsRelator',
        show: true
    },
    {
        icon: <Icon size='large' color='white' name='mail outline' />,
        text: 'Invitar',
        doClick: () => {
            // DeployedConsole.log('Invitando gente')
        },
        isCallable: false,
        show: true
    },
    {
        icon: <Icon size='large' color='white' name='microphone' />,
        text: 'Hablar a todos',
        doClick: (call: boolean) => {
            // DeployedConsole.log('Hablando a todos', call)
        },
        isCallable: true,
        show: true
    }
]

export default { Menu }