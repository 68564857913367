import React, { useState, useContext, useEffect } from 'react'
import EventFrame from '../../Components/EventFrame'

// import AvailableContext from '../../Library/AvailableContext';

import Blackboard from '../../Components/Blackboard';
import { IconFrameProps } from '../../Components/EventFrame/IconFrame';

import {
    Icon,
    Segment,
    Dimmer,
    Button,
    Header,
    Sidebar,
    Menu,
    Label
} from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications';
import { sendMessage } from '../../Utils';
import { socket } from '../../Services/Socket';
import ProfileContext from '../../Library/ProfileContext';
import Chat from '../../Components/Chat';
import Mosaic from '../../Components/Mosaic';
import RoomContext from '../../Library/RoomContext';
import { useHistory } from 'react-router-dom';


function ViewEvent() {
    const { inGroups, localUser, topMessage } = useContext(RoomContext)
    const { profile } = useContext(ProfileContext)
    const history = useHistory()

    const [requestWord, setRequestWord] = useState(false)

    const [chat, setChat] = useState(false)

    const [audio, setAudio] = useState(true)
    const [camera, setCamera] = useState(true)

    const [mosaic, setMosaic] = useState(false)
    const [messages, setMessages] = useState<any[]>([])

    const [leave, setLeave] = useState<boolean>(false)

    const { addToast } = useToasts()
    const dispatchMessageIO = (message: any) => {
        sendMessage(socket, message, null, addToast);
    }

    useEffect(() => {
        if (inGroups) {
            setMosaic(false)
            setChat(false)
        }
    }, [inGroups])

    socket.off('pluginRequestWordHandle').on('pluginRequestWordHandle', (message: any) => {
        if (requestWord){
            addToast('Se ha detenido la ronda de preguntas. Si deseas volver a pedir la palabra, presiona el botón.', { appearance: 'info', autoDismiss: true })
            setRequestWord(message.enable)
        }
    })

    const MenuView: IconFrameProps[] = [
        {
            icon: <Icon size='large' inverted={true} name='exchange' />,
            text: 'Salas disponibles',
            isCallable: true,
            doClick: () => {
                setLeave(!leave)
            },
            show: topMessage.icon === 'group' && [1, 2].includes(profile.clientProfileId || 4)
        },
        {
            icon: <Icon size='large' loading={requestWord} inverted={requestWord ? undefined : true} color={requestWord ? 'grey' : undefined} name={requestWord ? 'circle notch' : 'hand point up outline'} />,
            text: 'Pedir Palabra',
            doClick: () => {
                if(!requestWord){
                    addToast('Estás a la espera de que te den la palabra.', { appearance: 'info', autoDismiss: true })
                    setRequestWord(true)
                    dispatchMessageIO({
                        event: 'requestWord',
                        userId: profile.id,
                        name: profile.name,
                        roomId: 1,
                        socketId: profile.socketId
                    })
                }
            },
            show: true
        },
        // {
        //     icon: <Icon size='large' inverted={true} name='group' disabled={inGroups} />,
        //     text: 'Asistentes',
        //     isShow: !inGroups,
        //     doClick: () => {
        //         // DeployedConsole.log('viendo mosaico')
        //         if (!inGroups) {
        //             setMosaic(true)
        //         }
        //     },
        //     isCallable: false
        // },
        {
            icon: <Icon size='large' inverted={camera ? true : undefined} color={!camera ? 'red' : 'green'} name={!camera ? 'eye slash' : 'eye'} />,
            text: camera ? 'Detener Cámara' : 'Activar Cámara',
            doClick: () => {
                const newState = !camera
                localUser.setVideoActive(newState)
                setCamera(newState)
            },
            show: true
        },
        {
            icon: <Icon size='large' inverted={audio ? true : undefined} color={!audio ? 'red' : 'green'} name={!audio ? 'microphone slash' : 'microphone'} />,
            text: audio ? 'Detener Audio' : 'Activar Audio',
            doClick: () => {
                const newState = !audio
                localUser.setAudioActive(newState)
                setAudio(newState)
            },
            show: true
        },
        {
            icon: <Icon size='large' inverted={true} name='comment alternate outline' />,
            text: chat ? 'Ocultar chat' : 'Mostrar chat',
            isCallable: true,
            doClick: () => {
                setChat(!chat)
            },
            show: true
        },
        {
            icon: <Icon size='large' inverted={true} name='log out' />,
            text: 'Salir',
            doClick: () => {
                history.go(0)
            },
            show: true
        }
    ]
    
    socket.off('pluginchat').on('pluginchat', ({ user, message }: any) => {
        const update: any = { 
            user,
            message
         }

        setMessages([...messages, update])
    })

    const left = () => {
        setMosaic(false)
    }

    console.log({ inGroups })

    return <EventFrame options={MenuView} initialWebcam={{ video: true, audio: false }}>
        <Sidebar.Pushable as={Segment}>
                <Sidebar
                as={Menu}
                animation='overlay'
                icon='labeled'
                direction="right"
                inverted
                vertical
                visible={leave}
                width='very wide'>
                    <Menu.Item>
                        <Menu.Header style={{
                            fontSize: '20px'
                        }}>Administración</Menu.Header>
                        <Menu.Menu>
                            <Menu.Item name='inbox' as='a'>
                                <Label size="huge">-</Label>
                                <Header as='h4'>Grupo Equipo Newfield</Header>
                                <p>Ir al grupo Equipo Newfield, hay NaN personas.</p>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu.Item>

                    <Menu.Item>
                        <Menu.Header style={{
                            fontSize: '20px'
                        }}>Mis grupos</Menu.Header>
                        <Menu.Menu>
                            <Menu.Item name='inbox' as='a'>
                                <Label size="huge">-</Label>
                                <Header as='h4'>Mi grupo</Header>
                                <p>Ir a mi grupo, hay NaN personas.</p>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu.Item>
                    
                </Sidebar>

                <Sidebar.Pusher>
                        <><div className={`${inGroups ? 'inGroups ' : ''}`}>
                        <Dimmer active={mosaic} onClickOutside={left} page>
                            <>
                                <Button className="Mosaic--Close" circular icon='close' onClick={left} size="huge" color="red" />
                                <Mosaic />
                            </>
                        </Dimmer>
                        <Blackboard stateClassName={chat ? ' Blackboard--WithChat' : ''} setRequestWord={setRequestWord} />
                        {chat && <Segment inverted className="Event--Plugin--Chat">
                            <Chat ownState={true} ownMessages={messages} />
                        </Segment>}
                    </div>

                    
                        </>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </EventFrame>
    
}

export default ViewEvent