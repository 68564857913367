import React, { useState, useEffect } from 'react'
import { OpenVidu, Session } from 'openvidu-browser'

import { RoomContextType } from './types'
import LocalUser from './LocalUser'
import UserModel from '../../Models/User'
import { parseOVData } from '../../Utils'

// @ts-ignore
const RoomContext = React.createContext<RoomContextType>()

export function RoomConsumer ({children}: any) {
    /**
     * Room Context Logic.
     */
    const [myUserName, setMyUserName] = useState('')
    const [session, setSession] = useState<Session>()
    const [OV, setOV] = useState<OpenVidu>()
    const [suscribeds, setSuscribeds] = useState<any[]>([])
    const [enrollments, setEnrollments] = useState<any[]>([])
    const [candidates, setCandidates] = useState<any[]>([])
    const [subscribers, setSubscribers] = useState<any[]>([])
    const [streamCreated, listenStreamCreated] = useState(false)
    const [topMessage, setTopMessage] = useState({
        text: '',
        color: ''
    })
    const [conference, setConference] = useState({
        name: '...',
        agenda: '...',
        day: '...',
        module: ''
    })
    const [inGroups, setInGroups] = useState<boolean>(false)
    const [typeGroups, setTypeGroups] = useState<string>('')
    const [flashCandidate, setFlashCandidate] = useState<any>([])
    const [removeCandidate, setRemoveCandidate] = useState<any>([])

    const [currCoaching, setCurrCoaching] = useState<any>([])
    // const [flashSubscribersa, setFlashSubscribersa] = useState(null)

    const sendSignalUserChanged = (data: any) => {
        const signalOptions = {
            data: JSON.stringify(data),
            type: 'userChanged',
        };
        session?.signal(signalOptions);
    }

    /**
     * Local user Logic.
     */
    const localUser = LocalUser()

    /**
     * Subscribers logic.
     */
    
    const addCandidate = (stream: any) => {
        /**
         * databaseId: 48
         * email: "pzagal@newfield.cl"
         * refSocketId: "dzGYppHk6Gf_V5VZAAAa"
         * type: "participante"
         * username: "Paz Zagal"
         */
        const { serverData } = parseOVData(stream.connection.data)
        
        const isEnrolled = enrollments.findIndex(e => e.id === serverData.databaseId)
        if (isEnrolled > -1){
            enrollments[isEnrolled].online = true
            enrollments[isEnrolled].clientId = serverData.refSocketId
            setEnrollments([...enrollments])
        }
        // const refresh = [...candidates, stream]
        setFlashCandidate([...flashCandidate, stream])
        console.log(`[1]`, 'Llegó alguien: ', stream)
    }

    useEffect(() => {
        if (flashCandidate.length > 0){
            const [added, ...rest] = flashCandidate
            setCandidates([...candidates, added])
            setFlashCandidate(rest)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flashCandidate])

    useEffect(() => {
        console.log({ removeCandidate })
        if (removeCandidate.length > 0){
            const [removed, ...rest] = removeCandidate
            const ndx = candidates.findIndex((cnd) => {
                const { serverData } = parseOVData(cnd.connection.data)
                console.log({ serverData, removed })
                return serverData.databaseId === removed.databaseId
            })
            if (ndx > -1){
                candidates.splice(ndx)
                console.log({ candidates })
                setCandidates(candidates)
            }
            setRemoveCandidate(rest)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeCandidate])
    
    const addSubscribers = (subscriber: UserModel) => {
        setSubscribers([...subscribers, subscriber])
    }
    
    const subscribeToStreamCreated = () => {
        session!.on('streamCreated', (event: any) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { serverData } = parseOVData(event.stream.connection.data)

            if (serverData.type === 'relator') {
                connectToCandidate(event.stream)
            } else {
                if (serverData.type !== 'anfitrion'){
                setTimeout(() => {
                    addCandidate(event.stream)
                    console.log('Flasharé a ', serverData)
                }, serverData.databaseId * 10)
                }
            }
        });
    }
    
    const releaseCandidate = (clientSocketId: string) => {
        console.log('Liberaré candidato: ', clientSocketId)
        let ndx = -1
        suscribeds.forEach((suscriptor, key) => {
            const { serverData } = parseOVData(suscriptor.stream.connection.data)
            if (serverData.refSocketId === clientSocketId) {
                ndx = key
                session?.unsubscribe(suscriptor)
            }
        })

        if (ndx > -1){
            suscribeds.splice(ndx, 1)
            setSuscribeds(suscribeds)

            const subsNdx = subscribers.findIndex(s => s.refSocketId === clientSocketId)
            subscribers.splice(subsNdx, 1)
            setSubscribers(subscribers)
        }
    }

    const connectToCandidate = (stream: any) => {
        // @ts-ignore
        const subscriber = session!.subscribe(stream, undefined);
        subscriber.on('streamPlaying', (e: any) => {
            subscriber.videos[0].video.parentElement?.classList.remove('custom-class');
        });
        const newUser = new UserModel();
        newUser.setStreamManager!(subscriber);
        // @ts-ignore
        newUser.setConnectionId(stream.connection.connectionId);
        newUser.setType!('remote');
        
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let [clientData, serverData] = stream.connection.data.split('%/%')
        try {
            clientData = JSON.parse(clientData)
            serverData = JSON.parse(serverData)
        } catch (err) {
            //
        }

        newUser.setNickname(serverData.username);
        newUser.setRefSocketId(serverData.refSocketId);
        newUser.setRol(serverData.type);
        addSubscribers(newUser)

        setSuscribeds(suscribeds.concat(subscriber))
        return newUser
    }
    const subscribeToUserChanged = () => {
        session?.on('signal:userChanged', (event: any) => {
            console.log('USERCHANGED', { event })
            let remoteUsers = [...subscribers];
            remoteUsers.forEach((user: UserModel) => {
                if (user.getConnectionId!() === event.from.connectionId) {
                    const data = JSON.parse(event.data);
                    if (data.isAudioActive !== undefined) {
                        user.setAudioActive!(data.isAudioActive);
                    }
                    if (data.isVideoActive !== undefined) {
                        user.setVideoActive!(data.isVideoActive);
                    }
                    if (data.nickname !== undefined) {
                        user.setNickname!(data.nickname);
                    }
                    if (data.isScreenShareActive !== undefined) {
                        user.setScreenShareActive!(data.isScreenShareActive);
                    }
                }
            });
            setSubscribers(remoteUsers)
        });
    }

    /* const kick = (stream: any) => {
        const { serverData } = parseOVData(stream.connection.data)
        console.log({ serverData })
        const isEnrolled = enrollments.findIndex(e => e.id === serverData.databaseId)
        console.log({ isEnrolled, enrollments })
        if (isEnrolled > -1){
            enrollments[isEnrolled].online = false
            enrollments[isEnrolled].clientId = undefined
            setEnrollments([...enrollments])

        }
        setRemoveCandidate([...removeCandidate, {
            databaseId: serverData.databaseId
        }])
    } */

    const subscribeToStreamDestroyed = () => {
        // On every Stream destroyed...
        session!.on('streamDestroyed', (event: any) => {
            // Remove the stream from 'subscribers' array
            event.preventDefault();
            // kick(event.stream)
        });
    }
    

    useEffect(() => {
        if(localUser.connectionId !== ''){
            console.log({ localUser, streamCreated })
            if (!streamCreated){
                subscribeToUserChanged()
                subscribeToStreamDestroyed()
                listenStreamCreated(true)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localUser.connectionId])

    /**
     * When update subscribers.
     *
    useEffect(() => {
        /*if (localUser) {
            sendSignalUserChanged({
                isAudioActive: localUser.isAudioActive!(),
                isVideoActive: localUser.isVideoActive!(),
                nickname: localUser.getNickname!(),
                isScreenShareActive: localUser.isScreenShareActive!(),
            });
        }*
        // this.updateLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    
    }, [subscribers])*/

    

    return <RoomContext.Provider value={{
        localUser,
        myUserName,
        subscribers,
        enrollments,
        candidates,
        OV,
        session,
        topMessage,
        conference,
        inGroups,
        typeGroups,

        actions: {
            setMyUserName,
            setOV,
            setSession,
            setSubscribers,
            setEnrollments,
            setTopMessage,
            setConference,
            setInGroups,
            setTypeGroups,

            addSubscribers,
            sendSignalUserChanged,

            subscribeToStreamCreated,
            connectToCandidate,
            releaseCandidate,

            currCoaching,
            setCurrCoaching
            
        }        
    }}>{children}</RoomContext.Provider>
}

export const RoomProvider = RoomContext.Provider
export default RoomContext