import React, { useEffect, useState, useContext } from 'react'

import { List } from 'semantic-ui-react'
import { ParticipantType } from '../../Types'

import ParticipantListItem from './ParticipantListItem'
import { socket } from '../../Services/Socket'
import RoomContext from '../../Library/RoomContext'

export interface ParticipantListItemInstance extends ParticipantType {
    online?: boolean
    isCoaching?: boolean
    isPalabra?: boolean
}

const ParticipantList = () => {
    const [online, setOnline] = useState<ParticipantListItemInstance[]>([])

    const [flash,setFlash] = useState(false)
    
    // const { participants } = useContext(AvailableContext)
    const { enrollments, actions: { setEnrollments } } = useContext(RoomContext)
    
    socket.off('pluginRequestWord').on('pluginRequestWord', (message: any) => {
        /* const toupdated = window.allParticipants.find(ap => ap.databaseId === message.userId)
        if (toupdated) {
            toupdated.isPalabra = true
        } */
        setFlash(true)
        const index = enrollments.findIndex((e: any) => e.id === message.userId)
        if (index >= 0){
            const newEn = [...enrollments]
            newEn[index].isRequestWord = true
            setEnrollments(newEn)
            console.log('WORDREQ: Está pidiendo la Palabra', enrollments[index].nickname)
        }
    })

    socket.off('pluginRequestWordHandle').on('pluginRequestWordHandle', (message: any) => {
        /* for (const par of window.allParticipants) {
            par.isPalabra = false
        }*/
        sendFlash()
    })

    const sendFlash = () => {
        setFlash(!flash)
    }

    useEffect(() => {
        console.log('updating on ParticipantList')
        setOnline([...enrollments].sort(function(a: any, b: any){
            if(a.nickname.toLowerCase() < b.nickname.toLowerCase()) { return -1; }
            if(a.nickname.toLowerCase() > b.nickname.toLowerCase()) { return 1; }
            return 0;
        }).sort(function(x: any, y: any) {
            // true values first
            return (x.online === y.online)? 0 : x.online ? -1 : 1;
            // false values first
            // return (x === y)? 0 : x? 1 : -1;
        }))
    }, [enrollments, flash])

    // useEffect(() => {
    //     console.log('update enrollments on ParticipantList')
    //     let updated = []
    //     if (enrollments && enrollments.length > 0) {
    //         console.log('enrollments update')
    //         updated = enrollments.sort(function(a: any, b: any){
    //             if(a.nickname.toLowerCase() < b.nickname.toLowerCase()) { return -1; }
    //             if(a.nickname.toLowerCase() > b.nickname.toLowerCase()) { return 1; }
    //             return 0;
    //         })
            
    //         console.log({updated})
    //     }

    //     // DeployedConsole.log('>ñ> Actualizo lista de participantes...', updated)
    //     setOnline(updated)
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [enrollments])

    // DeployedConsole.log('🔵🔵🔵🔵🔵🔵 ParticipantList all', online, window.allParticipants)

    return <List divided verticalAlign='middle' selected>
        {/*online.map(participant => {
            return <List.Item key={`Participant--id${participant.databaseId}`}>
                <List.Content floated='left'>
                    <Label circular color={participant.online ? 'green' : 'red'} empty /> {participant.name}
                </List.Content>
                <List.Content floated='right'>
                    <Popup position='left center' size='tiny' inverted content='Dar la Palabra' trigger={
                        <Button icon size='mini' disabled={!participant.online} color={!participant.online ? 'black' : 'grey'}><Icon name='hand point up outline' /></Button>
                    } />
                    <Popup position='left center' size='tiny' inverted content='Pasar adelante' trigger={
                        <Button icon size='mini' disabled={!participant.online} color={!participant.online ? 'black' : 'grey'}><Icon name='balance' /></Button>
                    } />
                    {/*<Popup position='left center' size='tiny' inverted content='Detener' trigger={<Button icon size='mini' color='red'><Icon name='remove user' /></Button>} />}
                </List.Content>
            </List.Item>
        })*/
    online.map((participant: any, key: number) => {
        // DeployedConsole.log('participant')
        return <ParticipantListItem participant={participant} setFlash={sendFlash} key={key} />
    })}
    </List>
}

export default ParticipantList