import UIfx from 'uifx'
// import ErrorAudio from '../Assets/error.mp3'

const errorAudioInstance = new UIfx(
    './assets/error.mp3',
    {
        volume: 1,
        throttleMs: 100
    }
)

const dispatchToast = (message: any, { addToast }: any) => {
    errorAudioInstance.play()
    addToast(message.info, { appearance: message.type, autoDismiss: false })
}

export default dispatchToast