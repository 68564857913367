import React, { useState } from 'react'
import { Menu } from 'semantic-ui-react'

import { SocketEvent } from '../../Types/'

export type IconFrameProps = {
    icon: string | JSX.Element
    text: string
    
    isCallable?: boolean
    doClick?: any
    doEvent?: string | SocketEvent.Default | SocketEvent.HandleStream

    active?: boolean
    isShow?: boolean,
    show?: boolean
}

const IconFrame = ({
    icon,
    text,
    isCallable,
    doClick,
    doEvent,
    isShow
}: IconFrameProps) => {
    const isString = typeof icon === 'string'
    const [active, setActive] = useState(false)

    const onClick = (e:any) => {
        e.preventDefault()
        setActive(!active)
        if(doClick){
            doClick()
        }
    }

    return <Menu.Item onClick={onClick} className={`IconFrame ${isShow !== undefined ? isShow ? 'ShowInGroups' : 'HideInGroups' : ''  }`} key={`Icon-of-${text}`}>
        {isString ? <div className="IconFrame--Icon" style={{ background: `url(${icon})` }}>&nbsp;</div> :
        icon}
        {text}
    </Menu.Item>
}

export default IconFrame