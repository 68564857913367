import React, { useState, useContext, useEffect } from 'react'
import { ChangeEvent, FormEvent } from 'react'

import { PlatformProfile } from '../../Utils/types'

import {
    Button,
    Icon,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Label,
    List,
    FormProps,
    InputOnChangeData,
    Dimmer,
    Loader
} from 'semantic-ui-react'

import { useHistory } from 'react-router-dom'

import { calcFromMap, sendMessage } from '../../Utils'

import { useToasts } from 'react-toast-notifications'
import ProfileSelector from '../../Components/ProfileSelector'

import LoaderContext from '../../Library/LoaderContext'
import ProfileContext from '../../Library/ProfileContext'
import AvailableContext from '../../Library/AvailableContext'

import { socket } from '../../Services/Socket'
import RoomContext from '../../Library/RoomContext'

const Login = () => {
    const { addToast } = useToasts()
    const history = useHistory()
    
    const { loading, setLoading } = useContext(LoaderContext)
    const { profile, setProfile } = useContext(ProfileContext)
    const { conference } = useContext(RoomContext)
    const available = useContext(AvailableContext)

    // Referers on Requests to Server
    const [formdata, setFormdata] = useState({ username: '' })
    // Referers on Visibles States
    const [visibleLogin, setVisibleLogin] = useState(true)
    const [visibleSelector, setVisibleSelector] = useState(false)
    const [visibleLoaderStart, setVisibleLoaderStart] = useState(false)
    // Referers on Internal Views
    const initialSelectors: PlatformProfile[] = []
    const [selectorOptions, setSelectorOptions] = useState(initialSelectors)
    // Update profiles
    const [catcheds, setCatcheds] = useState({ options: [] })

    useEffect(() => {
      if (profile!.type !== 'none'){
        // DeployedConsole.log(`Se ha actualizado el perfil actual a ${profile.type}`)
        const routes = new Map([
          ['anfitrion', '/backstage'],
          ['relator', '/stream'],
          ['participante', '/event']
        ])

        if (routes.has(profile.type!)){
          setLoading(true)
          const path = routes.get(profile.type!)!
          socket.off('message')
          history.push(path)
        } else {
          // El perfil indicado no tiene ruta.
          setLoading(true)
        }
      }
    }, [profile, history, setLoading])

    useEffect(() => {
      const calculatedProfiles = calcProfiles(catcheds.options)
      setSelectorOptions(calculatedProfiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [available.relator, available.anfitrion, catcheds.options])

    useEffect(() => {
      if(visibleLoaderStart && available.relator) {
        setLoading(true)
        dispatchMessageIO({
            event: 'joinRoom',
            username: profile.mail,
            password: '',
            defaultSending: false,
            type: 'participante'
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleLoaderStart, available.relator, setLoading, profile.mail])

    socket.off('message').on('message', (message: any) => {
        // DeployedConsole.log('🟢 Mensaje recibido', message)
        setLoading(false)
        
        switch (message.event) {
          case 'availableLogins':
            setCatcheds({ options: message.options })
            catchProfiles(message.options, message.realname)
            break
          case 'waitingRelator':
            setProfile({
              ...profile,
              mail: formdata.username
            })
            /**
             * Evaluación de mi perfil, si solamente soy PARTICIPANTE.
            */
            setVisibleLogin(false)
            setVisibleLoaderStart(true)
            break
        }
      }
    )

    const conferenceOpened = available.server && available.anfitrion

    const handleStateData = (e: ChangeEvent<HTMLInputElement>, { name, value }: InputOnChangeData) => {
        let update = {}
        // @ts-ignore
        update[name] = value
        setFormdata({
        ...formdata,
        ...update
        })
    }
  
    const catchProfiles = (options: PlatformProfile[], name: string) => {
        setProfile({
            ...profile,
            name,
            mail: formdata.username
        })

        /* const isOnlyParticipant = options.reduce((acc, option) => {
          if (!acc && options.length === 1 && option.type === 'participante'){
            return true
          }
          return acc
        }, false) */

        const calculatedProfiles = calcProfiles(options)
        setSelectorOptions(calculatedProfiles)

        setVisibleLogin(false)
        setVisibleSelector(true)
    }

    const calcProfiles = (options: any[]) => {
      /**
         * Evaluación de mi perfil, este caso es
         * DE MÁS DE DOS PERFILES.
         */
        const profiles = options.map(option => {
          const { type, requestAuth } = option
          /**
           * Calcular ícono
           */
          const icon = calcFromMap([
            ['participante', 'eye'],
            ['sponsor', 'handshake'],
            ['relator', 'video camera'],
            ['anfitrion', 'configure']
          ], type, 'user outline')

          let availableOption = false
          // if (availableServer){
          if (!available.anfitrion && type === 'anfitrion') {
              availableOption = true
          }
          if (available.anfitrion){
              if (!available.relator && type === 'relator') {
                  availableOption = true
              }
              if (available.relator && ['participante', 'sponsor'].includes(type)){
                  availableOption = true
              }
          }
          // }
          /**
            * @todo useEffect when socket appears.
            */

          const requestText =
          availableOption ?
              (requestAuth ? 
              <><Icon name='lock'/> Este perfil requiere autentificación avanzada.</> :
              <>Puedes ingresar a la conferencia con este perfil.</>
              ) :
              (<>No se encuentra disponible este perfil porque se requiere {options.find(opt => opt.weight === option.weight+1)?.showname ?? `un perfil superior`}.</>)
          
          
          return {
              ...option,
              icon,
              available: availableOption,
              requestText,
              showLogin: false
          }
      })

      return profiles.sort((a,b) => (a.weight < b.weight) ? 1 : -1)
    }

    const useSubmit = (e: FormEvent<HTMLFormElement>, b: FormProps) => {
        e.preventDefault()
        
        if (!formdata!.username!){
         return addToast('Debes ingresar una dirección de correo para comenzar.', { appearance: 'warning', autoDismiss: true })
        }
        setLoading(true)
        doLogin(formdata!.username!)
    }

    const doLogin = (username: string) => {
        username = username.trim().toLowerCase()
        const message = {
            event: 'preJoinProfile',
            username
        }
        
        dispatchMessageIO(message)
    }

    const dispatchMessageIO = (message: any) => {
        sendMessage(socket, message, setLoading, addToast)
    }

    const backToTheLogin = () => {
        setLoading(true)
        setVisibleSelector(false)
        setVisibleLogin(true)
        setTimeout(() => {
            setLoading(false)
            setFormdata({ username: '' })
        }, 2000)
    }

    return (
        <div className="Login">
          {loading && <Dimmer active>
            <Loader content='Conectando a la aplicación...' />
          </Dimmer>}
          <Grid textAlign='center' verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 450 }}>
              <Header as='h2' icon>
                <Icon name='unlock' />
                <Header.Content>
                  {conference.name}
                  <Header.Subheader><strong>Agenda: </strong>{conference.agenda}</Header.Subheader>
                </Header.Content>
              </Header>
              <p style={{ textAlign: 'justify' }}>Bienvenido(a), ingresa tus credenciales para comenzar. Chequea los indicadores para conocer cuando comienza tu conferencia.</p>
              <List>
                <List.Item>
                  <Label circular color={conferenceOpened ? 'green' : 'red'} empty key='conferenceOpenedLabel' /> &nbsp;
                  {conferenceOpened ? `Conferencia en línea` : `Conferencia sin iniciar`}
                </List.Item>
                {conferenceOpened && 
                  <List.Item>
                    <Label circular color={available.relator ? 'green' : 'red'} empty key='availableRelatorLabel' /> &nbsp;
                    {available.relator ? `Relator conectado` : `Relator desconectado`}
                  </List.Item>
                }
              </List>
    
              {visibleLoaderStart && <Segment stacked>
                <Dimmer active>
                  <Loader indeterminate content='Esperando a que comience la conferencia...' />
                </Dimmer>
              </Segment>
              }
    
              {visibleSelector &&
                <ProfileSelector
                  options={selectorOptions}
                  backToTheLogin={backToTheLogin}
                  dispatchMessageIO={dispatchMessageIO}
                  profileLogged={profile}
                  addToast={addToast}
                />}
              
              {visibleLogin && <Form size='large' onSubmit={useSubmit}>
                <Segment stacked>
                  <Form.Input
                    inverted
                    fluid
                    autoFocus
                    icon='user'
                    name='username'
                    iconPosition='left'
                    placeholder='Ingresa tu correo corporativo...'
                    onChange={handleStateData}
                    loading={loading}
                    disabled={loading}
                  />
    
                  <Button color='black' fluid size='large'>
                    Ingresar
                  </Button>
                </Segment>
              </Form>}
              {(visibleLogin || visibleLoaderStart) && <Message>
                ¿Necesitas asistencia para ingresar? <strong>Hablemos aquí.</strong>
              </Message>}
            </Grid.Column>
          </Grid>
        </div>
      )
}

export default Login