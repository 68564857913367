import React, { useContext, useState, useEffect } from 'react'

import './styles.scss'
import ParticipantVideo from '../ParticipantVideo'

import { Icon } from 'semantic-ui-react'
import ProfileContext from '../../Library/ProfileContext'
import { socket } from '../../Services/Socket'
import { parseOVData } from '../../Utils'

import RoomContext from '../../Library/RoomContext'
import AvailableContext from '../../Library/AvailableContext'
import { UserOV } from '../../Library/RoomContext/types'
import UserModel from '../../Models/User'
// import { useToasts } from 'react-toast-notifications'

type Props = {
    stateClassName?: string
    setRequestWord?: any
}

const Blackboard = ({ stateClassName, setRequestWord }: Props) => {
    const { profile } = useContext(ProfileContext)
    const { relator } = useContext(AvailableContext)
    const { inGroups, typeGroups,localUser, subscribers, candidates, topMessage, actions: { setTopMessage, setTypeGroups, connectToCandidate, releaseCandidate, setInGroups, currCoaching, setCurrCoaching } } = useContext(RoomContext)

    // const [isInGroups, setIsInGroups] = useState(false)
    const [tlp, setTlp] = useState('')

    const [relatorVideo, setRelatorVideo] = useState<any>(null)
    const [tlpAlert, setTlpAlert] = useState(0)

    // const { addToast } = useToasts()
    
    /*useEffect(() => {
        if (tlpAlert > 4) {
            addToast('El anfitrión necesita que leas el telepronter.', { appearance: 'info', autoDismiss: true})
        }
    }, [tlpAlert])*/
    useEffect(() => {
        if (topMessage.text === '' && profile.type === 'participante'){
            setTopMessage({
                text: 'Estás como Oyente',
                subtext: 'Los participantes no pueden escucharte, puedes pedir la palabra o usar el "Chat".',
                color: 'black',
                icon: 'video camera'
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topMessage])

    /**
     * Plugins para perfil `RELATOR`.
     * 
     * @see {Telepronter}
     */
    if (profile.type === 'relator') {
        socket.off('pluginTelepronter').on('pluginTelepronter', (message: any) => {
            setTlpAlert(message.localText === tlp ? (tlpAlert + 1) : 0)
            setTlp(message.localText === '' ? '' : `Anfitrión: ${message.localText}`);
        })
    }

    /**
     * Plugin perfil de grupos.
     */
    socket.off('pluginGroup').on('pluginGroup', (message: any) => {
        const status = !message.status
        if (profile.type === 'relator') {
            // localUser.setVideoActive(!status)
        }
        console.log({ message })
        setTypeGroups(message.type)

        setInGroups(status)
        if(!status){
            setOthersVideos([])
        }
    })

    useEffect(() => {
        if (inGroups) {
            console.log({ typeGroups })
            const subtext = new Map([
                ['relator', 'Los participantes solamente pueden oír tu voz durante el trabajo en grupos.'],
                ['anfitrion', 'Los participantes están en trabajo en grupos.'],
                ['participante', 'Pueden verse y escucharse todos los integrantes del grupo.']
            ])
            setTopMessage({
                text: `Salón: Grupos ${typeGroups}`,
                subtext: subtext.get(profile.type),
                color: typeGroups === '' ? 'green' : 'purple',
                icon: 'group'
            })
        } else {
            setTopMessage({
                text: ''
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inGroups, typeGroups])

    /**
     * Plugins para perfil `PARTICIPANTE` o `EQUIPO/SPONSOR`.
     * 
     * @see {RequestWord}
     */
    if (profile.type !== 'relator'){
        socket.off('pluginRequestWord').on('pluginRequestWord', (message: any) => {
                    // others?.setRequestWord(false)
                    switch (message.event) {
                    case 'disableMediaDevices':
                        // myParticipant.rtcPeer.audioEnabled = false
                        localUser.setAudioActive(false)
                        break;
                    case 'enableMediaDevices':                
                        localUser.setAudioActive(true)
                        break;
                }
            })
    }

    /**
     * Funcionamiento General de Blackboard.
     */
    const [othersVideos, setOthersVideos] = useState<Array<UserModel|UserOV>>([])
    socket.off('blackboard').on('blackboard', (message: any) => {
        console.log('SOCKETIO: ', message)
        switch (message.event) {
            case 'newCoaching':
                // ¿Is me?
                if (localUser.refSocketId === message.clientSocketId) {
                    localUser.setAudioActive(true)
                    localUser.setVideoActive(true)
                    setOthersVideos(othersVideos.concat(localUser))
                    if (setRequestWord) {
                        setRequestWord(false)
                    }

                    if (!inGroups){
                        const subtext = new Map([
                            ['participante', 'Todos pueden verlos y oírlos.'],
                            ['anfitrion', 'Todos pueden verlos y oírlos.']
                        ])
                        setTopMessage({
                            text: 'Estás en Modo Coaching',
                            subtext: subtext.get(profile.type),
                            color: 'red',
                            icon: 'video camera'
                        })
                    }

                } else {
                    const candidate = candidates.find((candidate: any) => {
                        const { serverData } = parseOVData(candidate.connection.data)
                        return serverData.refSocketId === message.clientSocketId
                    })
                    if (candidate) {
                        // candidate.coaching = true
                        // Subscribers attempt to auto-connect
                        const newSubscribers = connectToCandidate(candidate)
                        // @ts-ignore
                        setOthersVideos(othersVideos.concat(newSubscribers))
                        setCurrCoaching([...currCoaching, message.clientSocketId])
                    }
                    if(profile.type === 'relator' && !inGroups){
                        setTopMessage({
                            text: 'Estás en Modo Coaching',
                            subtext: 'Todos pueden verlos y oírlos.',
                            color: 'red',
                            icon: 'video camera'
                        })
                    }
                }
                break
            case 'releaseCoaching':
                const ndx = othersVideos.findIndex(p => p.refSocketId === message.clientSocketId)
                setOthersVideos(othersVideos.filter((p, key) => key !== ndx ))

                // ¿Is me?
                if (localUser.refSocketId === message.clientSocketId) {
                    localUser.setAudioActive(false)
                    localUser.setVideoActive(true)
                    setTopMessage({ text: '' })
                } else {
                    // Desuscribo
                    releaseCandidate(message.clientSocketId)

                    setCurrCoaching(currCoaching.filter((id: string) => id !== message.clientSocketId))
                    // @todo revisar
                    if (!inGroups) {
                        // setTopMessage({ text: '' })
                    }
                }
                break
        }
    })

    /**
     * Connected current candidates.
     */
    const [oot, setOot] = useState(false)
    useEffect(() => {
        if (profile.startCoachingIds!.length > 0 && candidates!.length > 0 && !oot) {
            for (const socketIdRemote of profile.startCoachingIds!) {
                const candidate = candidates.find((candidate: any) => {
                    const { serverData } = parseOVData(candidate.connection.data)
                    return serverData.refSocketId === socketIdRemote
                })
                if (candidate) {
                    // Subscribers attempt to auto-connect
                    const newSubscribers = connectToCandidate(candidate)
                    // @ts-ignore
                    setOthersVideos(othersVideos.concat(newSubscribers))

                    setCurrCoaching(profile.startCoachingIds)
                }
            }
            setOot(true)
        }
        // console.log('DEBES CONECTARTE A ESTOS:', profile.startCoachingIds, candidates)
    // eslint-disable-next-line
    }, [profile.startCoachingIds, candidates]);

    /**
     * Connect self relator.
     */
    useEffect(() => {
        if (!relatorVideo){
            if (localUser.rol === 'relator'){
                if (localUser.connectionId && localUser.streamManager) {
                    // @ts-ignore
                    setRelatorVideo(localUser)
                }
            } else {
                const streamRelator = subscribers.find((user: UserModel) => user.refSocketId === relator)
                if (streamRelator) {
                    // @ts-ignore
                    setRelatorVideo(streamRelator)
                }
            }
        } else {
            if(!relator) {
                setRelatorVideo(null)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localUser.connectionId, localUser.streamManager, subscribers, relator])

    /*useEffect(() => {
        const othersExtraVideos = subscribers.filter(s => s.rol !== 'relator')
        setOthersVideos(othersExtraVideos)
    }, [subscribers])*/

    const groupsRelator = (!inGroups || (inGroups && ['relator', 'anfitrion'].includes(profile.type)))
    const qtyVideos = (relatorVideo && groupsRelator ? 1 : 0) + (othersVideos.length)
    return <div className={`Blackboard${stateClassName ? ` ${stateClassName}` : ''}`}>
        <div className={`Blackboard--VideoArea${qtyVideos > 1 ? ` Blackboard--VideoArea--HasOthers Blackboard--VideoArea--Has${qtyVideos}` : ''}`}>
            {relatorVideo && <div className={`Blackboard--Video Blackboard--MainVideo${qtyVideos > 1 ? ' BlackBoard--MainVideo--HasOthers' : ''}${inGroups && profile.type === 'participante' ? ' Blackboard--Groups--RelatorHidden' : ''}`}>
                <ParticipantVideo participant={relatorVideo} type='principal' />
                {tlp !== '' && <span className="Blackboard--Telepronter">{tlp}</span>}
            </div>}
            {othersVideos && othersVideos.map((participant, key) => 
                <ParticipantVideo participant={participant} type='secondary' className={`Blackboard--Video Blackboard--SecondaryVideo`} key={`Identify-${key}`} />
            )}
            {!qtyVideos && <div className="Blackboard--Splash">
                <span className="Blackboard--Splash--Message"><Icon loading name='spinner' /> Esperando al relator...</span>
            </div>}
        </div>
    </div>
}

export default Blackboard
