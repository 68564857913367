import React, { useContext, useState, useEffect } from 'react'
import { socket } from '../../Services/Socket'
import { Input, Form } from 'semantic-ui-react'
import AvailableContext from '../../Library/AvailableContext'
import RoomContext from '../../Library/RoomContext'
import UserModel from '../../Models/User'

const Telepronter = () => {
    // const participants: ParticipantType[] = window.rawParticipants
    const { relator } = useContext(AvailableContext)
    const { subscribers } = useContext(RoomContext)

    const [text, setText] = useState('')
    // const relatorName = participants.find(p => p.id === relator)?.name || 'relator (desconectado)'
    const [base, setBase] = useState('')
    const [emitted, setEmitted] = useState(`Telepronter con relator (desconectado)...`)

    useEffect(() => {
        if (relator) {
            const updateName = subscribers.find((user: UserModel) => user.refSocketId === relator)?.nickname
            setBase(updateName)
            setEmitted(`Telepronter con ${updateName}...`)
        } else {
            setEmitted(`Telepronter con relator (desconectado)...`)
        }
        
    }, [relator, subscribers])


    const handleSubmit = () => {
        if (!relator){
            setEmitted(`Telepronter con relator (desconectado)...`)
        } else {
            socket.emit('message', {
                event: 'telepronterSend',
                localText: text
            })
    
            if(text.length > 0){
                setEmitted(`En pantalla: ${text}`)
            } else {
                setEmitted(`Telepronter con ${base}...`)
            }
        }
        setText('')
    }

    const changeText = (e: any) => {
        setText(e.target.value)
    }

    return <div className="Plugin--Telepronter">
        <Form onSubmit={handleSubmit}>
            <Input icon='closed captioning outline' iconPosition='left' value={text} className='Plugin--Telepronter--Input' inverted fluid onChange={changeText} placeholder={emitted} />
        </Form>
    </div>
}

export default Telepronter