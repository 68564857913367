const parseOVData = (data: string) => {
    let clientData: any = {}
    let serverData: any = {}
    try {
        let [rawClientData, rawServerData] = data.split('%/%')
        clientData = JSON.parse(rawClientData)
        serverData = JSON.parse(rawServerData)
    } catch (err) {
                //
    }
    return { clientData, serverData }
}

export default parseOVData