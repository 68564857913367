class UserModel {
    connectionId: any;
    audioActive: any;
    videoActive: any;
    screenShareActive: any;
    nickname: any;
    streamManager: any;
    type: any; // 'remote' | 'local'
    refSocketId: string;
    rol: string;

    constructor() {
        this.connectionId = '';
        this.audioActive = false;
        this.videoActive = true;
        this.screenShareActive = false;
        this.nickname = '';
        this.streamManager = null;
        this.type = 'local';
        this.refSocketId = '';
        this.rol = '';
    }

    isAudioActive() {
        return this.audioActive;
    }

    isVideoActive() {
        return this.videoActive;
    }

    isScreenShareActive() {
        return this.screenShareActive;
    }

    getConnectionId() {
        return this.connectionId;
    }

    getNickname() {
        return this.nickname;
    }

    getStreamManager() {
        return this.streamManager;
    }

    isLocal() {
        return this.type === 'local';
    }
    isRemote() {
        return !this.isLocal();
    }
    setAudioActive(isAudioActive: any) {
        this.audioActive = isAudioActive;
    }
    setVideoActive(isVideoActive: any) {
        this.videoActive = isVideoActive;
    }
    setScreenShareActive(isScreenShareActive: any) {
        this.screenShareActive = isScreenShareActive;
    }
    setStreamManager(streamManager: any) {
        this.streamManager = streamManager;
    }

    setConnectionId(conecctionId: any) {
        this.connectionId = conecctionId;
    }
    setNickname(nickname: any) {
        this.nickname = nickname;
    }
    setRefSocketId(socketId: string) {
        this.refSocketId = socketId;
    }
    setRol(rol: string) {
        this.rol = rol;
    }
    setType(type: any) {
        if (type === 'local' || type === 'remote') {
            this.type = type;
        }
    }
}

export default UserModel;
