import React, { useContext, useState, useEffect } from 'react'
import EventFrame from '../../Components/EventFrame'


import Blackboard from '../../Components/Blackboard';

import { Segment, Icon, Dimmer, Button, Header, Sidebar, Menu, Label } from 'semantic-ui-react'
import { IconFrameProps } from '../../Components/EventFrame/IconFrame';
import Chat from '../../Components/Chat';
import { socket } from '../../Services/Socket';
import Mosaic from '../../Components/Mosaic';
import RoomContext from '../../Library/RoomContext';
import { useHistory } from 'react-router-dom';

function StreamEvent() {
    const history = useHistory()
    const { inGroups, localUser, /*session, OV*/ } = useContext(RoomContext)

    const [leave, setLeave] = useState<boolean>(false)

    // States
    const [chat, setChat] = useState(false)
    const [messages, setMessages] = useState<any[]>([])

    const [camera, setCamera] = useState(true)
    const [audio, setAudio] = useState(true)
    // const [screenShareLoad, setScreenShareLoad] = useState(false)
    const [mosaic, setMosaic] = useState(false)

    //const [flash, setFlash] = useState(false)

    /* useEffect(() => {
        if(flash) {
            session?.publish(localUser.getStreamManager()).then(() => {
                localUser.setScreenShareActive(true);
                setScreenShareLoad(false)
                // this.setState({ localUser: localUser }, () => {
                //     this.sendSignalUserChanged({ isScreenShareActive: localUser.isScreenShareActive() });
                // });
            });
            setFlash(false)
        }
    }, [flash, localUser.streamManager]) */

    useEffect(() => {
        if (!inGroups){
            setLeave(false)
        }
    }, [inGroups])

    const MenuStream: IconFrameProps[] = [
        {
            icon: <Icon size='large' inverted={true} name='exchange' />,
            text: !leave ? 'Salas disponibles' : 'Ocultar Salones',
            isCallable: true,
            doClick: () => {
                setLeave(!leave)
            },
            show: inGroups
        },
        {
            icon: <Icon size='large' inverted={true} name='comment alternate outline' />,
            text: chat ? 'Ocultar chat' : 'Mostrar chat',
            isCallable: true,
            doClick: () => {
                setChat(!chat)
            },
            show: true
        },
        // {
        //     icon: <Icon size='large' loading={screenShareLoad} inverted={screenShareLoad || localUser.isScreenShareActive!() ? undefined : true} color={screenShareLoad || localUser.isScreenShareActive!() ? 'grey' : undefined} name={screenShareLoad ? 'spinner' : 'picture'} />,
        //     text: localUser.isScreenShareActive!() ? 'Detener transmisión' : 'Compartir Pantalla',
        //     isCallable: false,
        //     doClick: () => {
        //         setScreenShareLoad(true)
        //         const videoSource = navigator.userAgent.indexOf('Firefox') !== -1 ? 'window' : 'screen';
        //         const publisher = OV!.initPublisher(
        //             // @ts-ignore
        //             undefined,
        //             {
        //                 videoSource: videoSource,
        //                 publishAudio: localUser.isAudioActive!(),
        //                 publishVideo: localUser.isVideoActive!(),
        //                 mirror: false,
        //             },
        //             (error: any) => {
        //                 if (error && error.name === 'SCREEN_EXTENSION_NOT_INSTALLED') {
        //                     alert('SCREEN_EXTENSION_NOT_INSTALLED');
        //                 } else if (error && error.name === 'SCREEN_SHARING_NOT_SUPPORTED') {
        //                     alert('SCREEN_SHARING_NOT_SUPPORTED');
        //                 } else if (error && error.name === 'SCREEN_EXTENSION_DISABLED') {
        //                     alert('SCREEN_EXTENSION_DISABLED');
        //                 } else if (error && error.name === 'SCREEN_CAPTURE_DENIED') {
        //                     alert('SCREEN_CAPTURE_DENIED');
        //                 }
        //             },
        //         );

        //         publisher.once('accessAllowed', () => {
        //             session!.unpublish(localUser.getStreamManager());
        //             localUser.setStreamManager(publisher);
        //             setFlash(true)
        //             console.log({ publisher })
        //         });
        //         // publisher.on('streamPlaying', () => {
        //         //     this.updateLayout();
        //         //     publisher.videos[0].video.parentElement.classList.remove('custom-class');
        //         // });
        //     },
        //     show: false
        // },
        {
            icon: <Icon size='large' inverted={camera ? true : undefined} color={!camera ? 'red' : 'green'} name={!camera ? 'eye slash' : 'eye'} />,
            text: camera ? 'Detener Cámara' : 'Activar Cámara',
            doClick: () => {
                const newState = !camera
                localUser.setVideoActive(newState)
                setCamera(newState)
            },
            show: true
        },
        {
            icon: <Icon size='large' inverted={audio ? true : undefined} color={!audio ? 'red' : 'green'} name={!audio ? 'microphone slash' : 'microphone'} />,
            text: audio ? 'Detener Audio' : 'Activar Audio',
            doClick: () => {
                const newState = !audio
                localUser.setAudioActive(newState)
                setAudio(newState)
            },
            show: true
        },
        {
            icon: <Icon size='large' inverted={true} name='group' />,
            text: 'Asistentes',
            doClick: () => {
                setMosaic(true)
            },
            isCallable: false,
            show: false
        },
        {
            icon: <Icon size='large' inverted={true} name='log out' />,
            text: 'Salir',
            doClick: () => {
                history.go(0)
            },
            show: true
        }
    ]

    socket.off('pluginchat').on('pluginchat', ({ user, message }: any) => {
        const update: any = { 
            user,
            message
         }

        setMessages([...messages, update])
    })

    const left = () => {
        setMosaic(false)
    }

    return <>
        <EventFrame options={MenuStream} initialWebcam={{ video: true, audio: true }}>
            <Sidebar.Pushable as={Segment}>
                <Sidebar
                as={Menu}
                animation='overlay'
                icon='labeled'
                direction="right"
                inverted
                vertical
                visible={leave}
                width='very wide'>
                    <Menu.Item>
                        <Menu.Header style={{
                            fontSize: '20px'
                        }}>Administración</Menu.Header>
                        <Menu.Menu>
                            <Menu.Item name='inbox' as='a'>
                                <Label size="huge">-</Label>
                                <Header as='h4'>Grupo Equipo Newfield</Header>
                                <p>Ir al grupo Equipo Newfield, hay NaN personas.</p>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu.Item>

                    <Menu.Item>
                        <Menu.Header style={{
                            fontSize: '20px'
                        }}>Grupos de Estudio</Menu.Header>
                        <Menu.Menu>
                            <Menu.Item name='inbox' as='a'>
                                <Label size="huge">-</Label>
                                <Header as='h4'>Grupo Demo 1</Header>
                                <p>Ir al grupo Demo 1, hay NaN personas.</p>
                            </Menu.Item>
                            <Menu.Item name='inbox' as='a'>
                                <Label size="huge" disabled>-</Label>
                                <Header as='h4'>Grupo Demo 2</Header>
                                <p>Ir al grupo Demo 2, hay NaN personas.</p>
                            </Menu.Item>
                            <Menu.Item name='inbox' as='a'>
                                <Label size="huge">-</Label>
                                <Header as='h4'>Grupo Demo 3</Header>
                                <p>Ir al grupo Demo 3, hay NaN personas.</p>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu.Item>
                    
                </Sidebar>

                <Sidebar.Pusher>
                    <>
                        <Dimmer active={mosaic} onClickOutside={left} page>
                            <>
                                <Button className="Mosaic--Close" circular icon='close' onClick={left} size="huge" color="red" />
                                <Mosaic />
                            </>
                        </Dimmer>
                        <Blackboard stateClassName={chat ? ' Blackboard--WithChat' : ''}/>
                        {chat && <Segment inverted className="Stream--Plugin--Chat">
                            <Chat ownState={true} ownMessages={messages} />
                        </Segment>}
                    </>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </EventFrame>

    </>
}

export default StreamEvent