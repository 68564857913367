import React, { useState, useEffect, ChangeEvent, useContext } from 'react';
import { PlatformProfile } from "../../Utils/types"

import {
    Button,
    Form,
    List,
    InputOnChangeData
  } from 'semantic-ui-react'
import LoaderContext from '../../Library/LoaderContext';

type ProfileItemProps = {
    profile: PlatformProfile
    index: number
    selected: number
    setSelected: React.Dispatch<React.SetStateAction<number>>
    dispatchMessageIO(message: any): void
    profileLogged: any
    addToast: any
}

const ProfileItem = ({ profile, index, selected, setSelected, dispatchMessageIO, profileLogged, addToast }: ProfileItemProps) => {
    const [active, setActive] = useState(false)
    const [password, setPassword] = useState('')
    const { loading, setLoading } = useContext(LoaderContext)

    useEffect(() => {
        if (selected !== index) { setActive(false) }
        
        if (active) { setSelected(index) }
    }, [active, setSelected, selected, index])

    const doClick = () => {
        if (profile.requestAuth) {
            setActive(true)
            setSelected(index)
        } else {
            goRoom()
        }
    }

    const goRoom = () => {
        if (profile.requestAuth && !password.length) {
            return addToast('Debes ingresar tu contraseña para continuar.', { appearance: 'warning', autoDismiss: true })
        }
        setLoading(true)
        dispatchMessageIO({
            event: 'joinRoom',
            username: profileLogged.mail,
            password,
            defaultSending: false,
            type: profile.type
        })
    }

    const handlePassword = (e: ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
        setPassword(value)
    }

    // profile.available ? 
    return <List.Item disabled={!profile.available} onClick={doClick} active={active} style={{ 
        paddingLeft: active ? '1em' : ''
    }} >
            <List.Icon name={profile.icon} size='large' verticalAlign='middle' />
            <List.Content as='a'>
                <List.Header>
                    {profile.showname}
                </List.Header>
                <List.Description>
                    {profile.requestText}
                </List.Description>
                
                {active && <Form style={{ marginTop: '10px' }} onSubmit={goRoom}>
                    <Form.Input
                        inverted
                        fluid
                        autoFocus
                        icon='user'
                        name='username'
                        type='password'
                        iconPosition='left'
                        onChange={handlePassword}
                        placeholder='Ingresa tu contraseña...'
                    />

                    <Button color='red' fluid size='large' loading={loading} disabled={loading}>
                        Autorizar
                    </Button>
                </Form>}

            </List.Content>
        </List.Item>
}

export default ProfileItem