import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ToastProvider } from 'react-toast-notifications'
import { RoomConsumer } from './Library/RoomContext';
import LogRocket from 'logrocket'

LogRocket.init('yuio6n/front-socket')
/*
window.rawParticipants = []
window.allParticipants = []
*/

ReactDOM.render(
    <ToastProvider placement='bottom-right'>
      <RoomConsumer>
          <App />
      </RoomConsumer>
    </ToastProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
