import React, { useContext, useState, useEffect } from 'react'
import { socket } from '../../Services/Socket'
import ProfileContext from '../../Library/ProfileContext'
import { List, Input, Form } from 'semantic-ui-react'

type ChatProps = {
    ownState?: boolean

    ownMessages?: any[]
}

const Chat = ({ ownState, ownMessages }: ChatProps) => {
    let [messages, setMessages] = useState([])
    const [text, setText] = useState('')

    const { profile } = useContext(ProfileContext)

    if (!ownState){
        socket.off('pluginchat').on('pluginchat', ({ user, message }: any) => {
            const update: any = { 
                user,
                message
             }
    
            // @ts-ignore
            setMessages([...messages, update])
        })
    } else {
        // @ts-ignore
        messages = ownMessages
    }

    useEffect(() => {
        const list = document.getElementsByClassName('Plugin--Chat--ListMessages')[0]
        list.scrollTop = list.scrollHeight
    // eslint-disable-next-line no-sparse-arrays
    }, [,messages])

    const handleSubmit = () => {
        if (text.length > 0) {
            socket.emit('pluginchat', {
                user: profile.name,
                message: text
            })

            setText('')
        }
    }

    const changeText = (e: any) => {
        setText(e.target.value)
    }

    return <div className="Plugin--Chat">
         <List divided relaxed className='Plugin--Chat--ListMessages'>
            {messages.map(({ user, message }: any) => <List.Item icon='user' content={<><strong>{user}:</strong> {message}</>} />)}
        </List>
        <Form onSubmit={handleSubmit}>
            <Input icon='comments outline' iconPosition='left' value={text} className='Plugin--Chat--Input' inverted fluid onChange={changeText} placeholder='Ingresar mensaje de chat...' />
        </Form>
    </div>
}

export default Chat