import React, { useState, useContext, useEffect } from 'react'

import './App.scss'

import { useToasts } from 'react-toast-notifications'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

// Services
import { socket } from './Services/Socket'

// Views
import Login from './Views/Login'
import Backstage from './Views/Backstage'
import StreamEvent from './Views/StreamEvent'
import ViewEvent from './Views/ViewEvent'

// Contexts State Management
import { LoaderProvider } from './Library/LoaderContext'
import ProfileContext, { ProfileProvider } from './Library/ProfileContext'
import { MessageProvider } from './Library/MessageContext'

// Events
import { dispatchToast, meProfile } from './Events'

// Types
import { RoomInfoType, ParticipantType } from './Types'
import { AvailableProvider } from './Library/AvailableContext'
import RoomContext from './Library/RoomContext/'

import axios from 'axios'

function App() {
  const { addToast } = useToasts()

  /**
   * Mobile: < 480
   * Tablet > 480 && < 768
   * Desktop > 960
   */
  const [width, setWidth] = useState(window.innerWidth)
  const breakpoint = 960
  const [isMobile, setIsMobile] = useState(width < breakpoint)

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
  }, [])
  
  useEffect(() => {
    setIsMobile(width < breakpoint)
  }, [width])

  const [message, setMessage] = useState({})
  const [loading, setLoading] = useState(true)
  // Referers on Socket.io
  const [server, setServer] = useState(false)
  const [anfitrion, setAnfitrion] = useState(false)
  const [relator, setRelator] = useState('')
  // Referers on Profile
  const [profile, setProfile] = useState({
    id: 0,
    socketId: '',
    name: '',
    photo: '',
    type: 'none',
    isSending: false,
    incomingMedia: null,
    outgoingMedia: null,
    mail: '',
    token: ''
  })
  // Referers on Participants
  const initialParticipants: ParticipantType[] = []
  const [participants, setParticipants] = useState(initialParticipants)
  
  

  // Referers on MediaStreams
  const [webcam, setWebcam] = useState()
  const [screen, setScreen] = useState()

  const { actions: { setEnrollments, setConference, setInGroups, setTypeGroups }, inGroups } = useContext(RoomContext)

  socket.on("connect_error", (data: any) => {
    if (data!.type === 'TransportError') {
      setServer(false)
    }
  })
  
  /**
   * Manejo de conexión al servidor
   * @todo Manejar timeout y tiempo de reconexión / intentos.
   */
  socket.on("connect", async () => {
    setServer(true)
    try {
      const data = await axios.get('/store.config')
      if (data && data.status === 200 && data.data) {
        setConference({
          ...data.data.workus
        })
      }
    } catch (err) {
      /**
       * @todo Handling error
       */
    }
    setLoading(false)
  })

  /**
   * Manejo del estado de la sala.
   */
  socket.off('roomInfo').on('roomInfo', (message: RoomInfoType) => {
    const { mainAnfitrion, mainRelator, isInGroups: remoteGroups, typeGroup } = message
    console.log('Update ROOMINFO', { message }  )
    setAnfitrion(mainAnfitrion.length > 0)
    setRelator(mainRelator)
    setInGroups(remoteGroups)
    setTypeGroups(typeGroup)
  })

  /**
   * Manejo de interacciones de la experiencia del usuario.
   */
  socket.off('uxaction').on('uxaction', (message: any) => {
    // DeployedConsole.log('🟡 Acción UX recibida', message)
    setLoading(false)

    const events = new Map([
      ['dispatchToast', dispatchToast],
      ['meProfile', meProfile]
    ])
    try {
      const dispatch = events.get(message.event)
      // @ts-ignore
      dispatch(message, {
        addToast,
        setProfile,
        setAnfitrion,
        setRelator,
        profile,
        setEnrollments
      })
    } catch (err) {
      /**
       * @todo Controlar error de evento `uxaction` no manejado.
       */
    }
  })

  /**
   * Validación antes de cerrar la ventana.
   */
  window.addEventListener('beforeunload', (e: any) => {
      e.preventDefault()
      return e.returnValue = '¿Estás seguro que deseas dejar de participar aquí?'
  })


  return <div className={`App ${isMobile ? 'isMobile' : 'isDesktop'}${inGroups ? ' inGroups' : ''}`}>
    <BrowserRouter>
        <AvailableProvider value={{ server, anfitrion, relator, participants, setParticipants }}>
          <MessageProvider value={{ message, setMessage }}>
            <LoaderProvider value={{ loading, setLoading }}>
              <ProfileProvider value={{ profile, setProfile, streams: { webcam, setWebcam, screen, setScreen } }}>
                <Switch>
                    <Route path="/" exact>
                      <Login />
                    </Route>
                    <PrivateRoute path="/backstage" exact requiredProfile={["anfitrion"]}>
                        <Backstage />
                    </PrivateRoute>
                    <PrivateRoute path="/stream" exact requiredProfile={["relator"]}>
                        <StreamEvent />
                    </PrivateRoute>
                    <PrivateRoute path="/event" exact requiredProfile={["participante"]}>
                        <ViewEvent />
                    </PrivateRoute>
                  </Switch>
                </ProfileProvider>
              </LoaderProvider>
            </MessageProvider>
          </AvailableProvider>
    </BrowserRouter>  
  </div>  
}

function PrivateRoute({ children, requiredProfile, ...rest }: any) {
  const { profile } = useContext(ProfileContext)
  return (
    <Route

      {...rest}
      render={({ match }) => {
        const isValid = requiredProfile.includes(profile.type)
        return isValid ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: {
                from: match.path,
                requiredProfile
              }
            }}
          />
        )
      }}
    />
  )
}

export default App
