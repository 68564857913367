import React, { useContext, useState, useEffect } from 'react'

import './Mosaic.scss'
import RoomContext from '../../Library/RoomContext'
import ParticipantVideo from '../ParticipantVideo'
import { parseOVData } from '../../Utils'

const suscritos: any[] = []

const Mosaic = () => {
    /* socket.off('blackboardMosaic').on('blackboardMosaic', (message: any) => {

    }) */
    const { candidates, actions: { connectToCandidate, releaseCandidate, currCoaching } } = useContext(RoomContext)
    const [videos, setVideos] = useState<any[]>([])
    const [flash, setFlash] = useState<any>(null)

    // const [page, setPage] = useState<number>(1)

    useEffect(() => {
        if (flash) {
            setVideos([...videos, flash])
            // setFlash(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flash])

    useEffect(() => {
        setFlash(null)
    }, [videos])

    useEffect(() => {
        candidates.forEach((candidate: any) => {
            const { serverData } = parseOVData(candidate.connection.data)
            console.log('Añadiendo a mosaico a: ', serverData.username)
            // if (serverData.type === 'participante'){            
                // connectToCandidate(candidate)
                setTimeout(() => {
                    const newSubscribers = connectToCandidate(candidate)
                    suscritos.push(newSubscribers)
                    setFlash(newSubscribers)
                    console.log('Flasheo para ', serverData.username)
                }, 200)
            // }
        })
        return () => {
            candidates.forEach((candidate: any) => {
                const { serverData } = parseOVData(candidate.connection.data)
                if (!currCoaching.includes(serverData.refSocketId)){
                    if (serverData.type === 'participante'){
                        releaseCandidate(serverData.refSocketId)
                        console.log('Liberando: ', serverData.refSocketId)
                    }
                }
            })
        }
    /* eslint-disable*/
    }, [])
    /* eslint-enable*/

    /*useEffect(() => {
        // subscribers.forEach((subs: any) => {
        //     setFlash(subs)
        // })
        
    }, [])*/


    return <div className="Plugin--Mosaic">
        {videos && videos.map((participant, key) => 
                <ParticipantVideo mute={true} participant={participant} type='secondary' className={`Blackboard--Video Blackboard--SecondaryVideo`} key={`Identify-${key}`} />
        )}
        {/* {photos && photos.map(photo => <img src={photo.image} alt={photo.clientId} />)} */}
    </div>
}

export default Mosaic