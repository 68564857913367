export const endpoint = process.env.REACT_APP_SOCKET_URL || 'https://dbt-apiremote-54ypm.ondigitalocean.app/'
export const ovpoint = process.env.REACT_APP_OV_POINT || 'https://dbtremote.alnus.cl/openvidu/api'

export const mediaConstraints = {
    audio: true,
    video : {
        mandatory : {
            maxWidth : 320,
            maxFrameRate : 15,
            minFrameRate : 15
        }
    }
}

export default {
    profileTypes: new Map([
        ['anfitrion', 'Anfitrión'],
        ['relator', 'Relator'],
        ['participante', 'Participante']
    ])
}