import React, { useContext, useEffect, useState } from 'react'
import {
    Segment,
    Menu,
    Label,
    Rating,
    Dimmer,
    Loader,
    Icon,
    Header
} from 'semantic-ui-react'

import './styles.scss'

import IconFrame, { IconFrameProps } from './IconFrame'
import AvailableContext from '../../Library/AvailableContext'
import ProfileContext from '../../Library/ProfileContext'
import { useLocation } from 'react-router-dom'
import { socket } from '../../Services/Socket'

import { OpenVidu } from 'openvidu-browser'
import RoomContext from '../../Library/RoomContext'
import UserModel from '../../Models/User'
import LoaderContext from '../../Library/LoaderContext'

export type WebcamOptions = {
    video: boolean
    audio: boolean
}

type EventFrameProps = {
    children: JSX.Element
    options: IconFrameProps[]
    leftMenu?: IconFrameProps[]
    initialWebcam: WebcamOptions
}

const EventFrame = ({ children, options, leftMenu, initialWebcam: { video, audio } }: EventFrameProps) => {
    const location = useLocation()
    const currentProfile = `${location.pathname.substr(1,1).toUpperCase()}${location.pathname.substr(2)}`

    const { conference, topMessage, localUser, subscribers, session, OV, actions: { setOV, setSession, subscribeToStreamCreated, sendSignalUserChanged } } = useContext(RoomContext)
    // setSubscribers
    const { relator } = useContext(AvailableContext)
    const { loading, setLoading } = useContext(LoaderContext)
    const { profile } = useContext(ProfileContext)

    const isRelator = relator.length > 0
    const isParticipant = profile.type === 'participante'

    const [rtg, setRtg] = useState(0)
    const [stateTmp, setStateTmp] = useState(false)
    const [relatorName, setRelatorName] = useState('')

    const isCalculatingTemperature = false

    /**
     * Entry point and Exit point unmount
     * 
     * @step Set loading to waiting permissions of Camera and Microphone
     * @step Initialize OV
     * 
     * @see {OpenVidu::_construct()}
     */
    useEffect(() => {
        setLoading(false)
        setOV(new OpenVidu())
        localUser.setAudioActive(audio)
        localUser.setVideoActive(video)
        

        if (profile.type === 'relator') {
            setRelatorName(profile.name)
        }

        return () => {
            if (session){
                session.disconnect();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * When OV is Available
     * 
     * @step If no has active session, create.
     * 
     * @see {OV.initSession}
     */
    useEffect(() => {
        if (!session) {
            setSession(OV?.initSession())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OV])

    /**
     * When Session is Available
     * 
     * @step Connect to OV when profile.token.
     * @step Is success, set initPublisher.
     * @step Set localUser data and StreamManager.
     * @step Send signals to room.
     * 
     * @see {session.connect}
     * @see {OV.initPublisher}
     * @see {localUser}
     */
    useEffect(() => {
        if (typeof session !== 'undefined' && profile && profile.token){
            console.log('STRC listening')
            subscribeToStreamCreated()

            session.connect(profile.token, {
                isRequestWord: false,
                isCoaching: false,
                isSharedScreen: false
            }).then(() => {
                // @ts-ignore
                const publisher = OV?.initPublisher(undefined, {
                    audioSource: undefined,
                    videoSource: undefined,
                    publishAudio: localUser.isAudioActive!(),
                    publishVideo: localUser.isVideoActive!(),
                    resolution: localUser.rol === 'relator' ? '640x480' : '320x240',
                    frameRate: localUser.rol === 'relator' ? 30 : 24,
                    insertMode: 'APPEND',
                })!
        
                if (session.capabilities.publish) {
                    session.publish(publisher).then(() => {
                        setLoading(false)
                    })
                }

                localUser.setScreenShareActive(false);
                localUser.setStreamManager(publisher);
                localUser.setNickname(profile?.name);
                localUser.setRefSocketId(profile?.socketId);
                localUser.setRol(profile?.type);
                localUser.setConnectionId(session.connection.connectionId!)
                
                sendSignalUserChanged({ isScreenShareActive: localUser.isScreenShareActive!() });
            })
            console.log('sesion creada')
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session])

    /**
     * Find a Relator
     */
    useEffect(() => {
        if (profile.type !== 'relator') {
            const updateName = subscribers.find((user: UserModel) => user.refSocketId === relator)?.nickname
            setRelatorName(updateName)
        }
    }, [profile.type, relator, subscribers])

    const handleRate = (e: any, { rating }: any) => {
        e.preventDefault()
        socket.emit('message', {
            event: 'addTemperature',
            percent: rating,
            userId: profile.id,
            roomId: 1
        })
        setStateTmp(false)
    }
    
    if(isParticipant) {
        socket.off('pluginTemperature').on('pluginTemperature', (message: any) => {
            setStateTmp(message.enable)
        })
    } else {
        socket.off('pluginTemperature').on('pluginTemperature', (message: any) => {
            if(message.event === 'updateTemperature'){
                setRtg(Math.ceil(message.average))
            }
        })
    }

    return <div className={`EventFrame EventFrame--${currentProfile}`}>
        {loading && <Dimmer active>
            <Loader content='Esperando autorización a Cámara y Micrófono...' />
          </Dimmer>}
    <Segment.Group horizontal className="TopFrame TopFrame--Inverted">
        <Segment style={{ width: '36%' }}>
            {leftMenu && 
                <Menu className="labeled" compact icon={true} inverted style={{
                    float: 'left',
                    marginRight: '32px'
                }}>
                    {leftMenu.map(option => <IconFrame {...option} key={`IconFrame--${option.text.trim().replace(' ', '')}`} />)}
                </Menu>
            }
            <strong>{conference.name}</strong><br />{conference.day}<br />
            <strong>Módulo: </strong>{conference.module}<br />
            <Label circular color={isRelator ? 'green' : 'red'} empty /> <strong>Relator: </strong>{isRelator ? relatorName : 'No disponible'}
        </Segment>
        {topMessage.text && <Segment style={{ width: '28%' }} className='TopFrame--Wizzard' color={topMessage.color || 'black'}>
            <Header as='h2' inverted={true}>
                <Icon name={topMessage.icon} inverted={true} />
                <Header.Content>
                    {topMessage.text}
                    <Header.Subheader>{topMessage.subtext}</Header.Subheader>
                </Header.Content>
            </Header>
        </Segment>}
        {isCalculatingTemperature && (stateTmp || !isParticipant) && <Segment style={{ width: '40%' }} className="Temperature">
            <Rating icon='heart' defaultRating={0} maxRating={5} size="huge" rating={rtg} disabled={!isParticipant} onRate={handleRate} inverted /><br />
            {isParticipant ? <>¿Cuánto te interesa lo que está pasando?</> : <>Interés del Salón</>}
        </Segment>}
        <Segment style={{ width: '36%' }}>
            {options && 
                <Menu className="labeled" compact icon={true} inverted>
                    {options.filter(option => option.show).map(option => <IconFrame {...option} key={`IconFrame--${option.text.trim().replace(' ', '')}`} />)}
                </Menu>
            }
        </Segment>
        
    </Segment.Group>
    {children}
    </div>
}

export default EventFrame