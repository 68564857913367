import { ProfileType } from "../Types"
// import RoomContext from "../Library/RoomContext"
import LogRocket from 'logrocket'

const meProfile = (message: any, { profile, setProfile, setEnrollments }: any) => {
    console.log({ message })
    const { user } = message
    if (user.type === 'anfitrion') {
        setEnrollments(message.currentEnrollments.map((e: any) => {
            return {
                ...e,
                online: false,
                isCoaching: false,
                isRequestWord: false
            }
        }))
    }

    const updateProfile: ProfileType = {
        ...profile,
        id: user.databaseId,
        socketId: user.id,
        name: user.name,
        photo: user.photo,
        type: user.type,
        isSending: user.defaultSending,
        incomingMedia: user.incomingMedia,
        outgoingMedia: user.outgoingMedia,
        token: user.token,
        startCoachingIds: user.coachingIds,
        clientProfileId: user.clientProfileId
    }
    
    setProfile(updateProfile)
    LogRocket.identify(user.databaseId, {
        name: user.name,
        socketId: user.id,
        type: user.type
    })
}

export default meProfile