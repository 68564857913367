import React from 'react'
import { ParticipantType } from '../Types'

type ContextType = {
    server: boolean
    anfitrion: boolean
    relator: string
    participants: ParticipantType[]
    setParticipants: any
}

const initialState: ContextType = {
    server: false,
    anfitrion: false,
    relator: '',
    participants: [],
    setParticipants: () => {}
}

const AvailableContext = React.createContext(initialState)

export const AvailableProvider = AvailableContext.Provider
export default AvailableContext