import React, { useContext, useState } from 'react'
import EventFrame from '../../Components/EventFrame'

import MenuBackstage from './Menu';
import Blackboard from '../../Components/Blackboard';

import Chat from '../../Components/Chat';
import { Segment, Menu, Icon, Modal, Button, Form } from 'semantic-ui-react';

import './styles.scss'
import Telepronter from '../../Components/Telepronter';
import IconFrame, { IconFrameProps } from '../../Components/EventFrame/IconFrame';
// import ParticipantList from '../../Components/ParticipantList';
import { useToasts } from 'react-toast-notifications';
import { sendMessage } from '../../Utils';
import { socket } from '../../Services/Socket';
import ProfileContext from '../../Library/ProfileContext';
import ParticipantList from '../../Components/ParticipantList';
import RoomContext from '../../Library/RoomContext';

function Backstage() {    
    const { profile } = useContext(ProfileContext)
    const { enrollments, inGroups , actions: { setEnrollments } } = useContext(RoomContext)
    
    // const [interes] = useState(false)
    const [gruposModal, setGruposModal] = useState(false)
    const [resetQtns, setResetQtns] = useState(false)

    const [inCoaching, setInCoaching] = useState(false)
    const { addToast } = useToasts()
    const dispatchMessageIO = (message: any) => {
        sendMessage(socket, message, null, addToast);
    }
        
    const LeftMenu: IconFrameProps[] = [
        {
            // @ts-ignore
            icon: <Icon size='large' color='white' name='microphone' />,
            text: 'Hablar al Relator',
            doClick: (call: boolean) => {
                // DeployedConsole.log('Hablando a todos', call)
            },
            isCallable: true,
            show: true
        },
        {
            // @ts-ignore
            icon: <Icon size='large' color={inCoaching ? 'grey' : 'white'} name='video camera' />,
            text: inCoaching ? 'Salir de Coaching' : 'Transmitir en Coaching',
            doClick: () => {
                if(!inCoaching){
                    setInCoaching(true)
                    dispatchMessageIO({
                        event: 'requestWordAssign',
                        userId: profile.id,
                        roomId: 1,
                        socketId: profile.socketId
                    })
                } else {
                    setInCoaching(false)                    
                    dispatchMessageIO({
                        event: 'requestWordRelease',
                        userId: profile.id,
                        socketId: profile.socketId
                    })
                }
            },
            isCallable: true,
            show: true
        }
    ]
    
    const ToolbarMenu: IconFrameProps[] = [
        {
            // @ts-ignore
            icon: <Icon size='large' color={'white'} loading={resetQtns} disabled={resetQtns} name={resetQtns ? 'asterisk' : 'stopwatch'} />,
            text: 'Reestablecer preguntas',
            doClick: () => {
                if(!resetQtns){
                    setResetQtns(true)
                    setInterval(() => {
                        setResetQtns(false)
                    }, 1500)
                    dispatchMessageIO({
                        event: 'disableQuestions'
                    })
                    setEnrollments(enrollments.map((e: any) => {
                        e.isRequestWord = false
                        return e
                    }))
                }
            },
            show: true
        },
        {
            icon: <Icon size='large' color={'red'} name='refresh' />,
            text: 'Reestablecer Blackboard',
            doClick: () => {
                dispatchMessageIO({
                    event: 'panicButton'
                })
            },
            show: true
        },
        /*{
            // @ts-ignore
            icon: <Icon size='large' disabled={true} color={'white'} loading={interes} name={interes ? 'asterisk' : 'heartbeat'} />,
            text: 'Medir interés',
            doClick: () => {
                /* if(!interes){
                    setInteres(true)
                    setInterval(() => {
                        setInteres(false)
                    }, 1500)
                    dispatchMessageIO({
                        event: 'requestTemperature'
                    })
                }
            },
            isCallable: true
        },*/
        /*{
            // @ts-ignore
            icon: <Icon size='large' color='white' name='hide' />,
            text: 'Pausar transmisión'
        },
        {
            // @ts-ignore
            icon: <Icon size='large' color='white' name='assistive listening systems' />,
            text: 'Bloquear Mosaico'
        },*/
        {
            // @ts-ignore
            icon: <Icon disabled size='large' color='white' name={inGroups ? 'stop ': 'group'} />,
            text: inGroups ? 'Detener Grupos' : 'Grupos al Azar',
            doClick: () => {
                if (inGroups) {
                    dispatchMessageIO({
                        event: 'startGroup',
                        size: 0,
                        bajarar: false
                        /*apiParticipants.reduce<Array<string>>((acc, p) => {
                            if (p.type === 'participante') {
                                acc.push(p.id!)
                            }
                            return acc
                        }, [])*/
                    })
                } else {
                    setGruposModal(true)
                }
            },
            show: true
        },
        {
            // @ts-ignore
            icon: <Icon size='large' color='purple' name='group' />,
            text: 'Grupos de Estudio',
            doClick: () => {
                dispatchMessageIO({
                    event: 'startGroupStudy'
                })
            },
            show: true
        }
    ]

    const [sizeG, setSizeG] = useState(0)

    const handleStateData = (e: any, { value }: any) => {
        setSizeG(value)
    }

    return (
        <EventFrame options={MenuBackstage.Menu} initialWebcam={{ video: false, audio: false }} leftMenu={LeftMenu}>
            <>
                <Modal
                    dimmer='blurring'
                    open={gruposModal}
                    onClose={() => setGruposModal(false)}
                    basic
                >
                    <Modal.Header>Grupos</Modal.Header>
                    <Modal.Content>
                    Determina el grupo
<Form>
                        <Form.Input
                            name='size'
                            placeholder='Tamaño'
                            onChange={handleStateData}
                        />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                    <Button negative onClick={() => setGruposModal(false)}>
                        Salir
                    </Button>
                    <Button positive onClick={() => {
                        dispatchMessageIO({
                            event: 'startGroup',
                            // inGroups ? 0 : Number(prompt('Digita el tamano de los grupos, por favor:'))
                            size: sizeG,
                            bajarar: true
                            /*apiParticipants.reduce<Array<string>>((acc, p) => {
                                if (p.type === 'participante') {
                                    acc.push(p.id!)
                                }
                                return acc
                            }, [])*/
                        })
                        setGruposModal(false)
                    }}>
                        Crear grupos NUEVOS
                    </Button>

                    <Button positive onClick={() => {
                        dispatchMessageIO({
                            event: 'startGroup',
                            // inGroups ? 0 : Number(prompt('Digita el tamano de los grupos, por favor:'))
                            size: sizeG,
                            bajarar: false
                            /*apiParticipants.reduce<Array<string>>((acc, p) => {
                                if (p.type === 'participante') {
                                    acc.push(p.id!)
                                }
                                return acc
                            }, [])*/
                        })
                        setGruposModal(false)
                    }}>
                        Crear grupos ANTERIORES
                    </Button>
                    </Modal.Actions>
                </Modal>
                <div className="Backstage--Left">
                    <Blackboard />
                    <Segment inverted className="Backstage--Plugin--Telepronter">
                        <Telepronter />
                    </Segment>
                    <Segment inverted className="Backstage--Plugin--Chat">
                        <Chat />
                    </Segment>
                </div>
                <div className="Backstage--Right">
                    <Segment inverted className="Backstage--Tools">
                        <Menu className="labeled" compact icon={true} inverted>
                            {ToolbarMenu.map(option => <IconFrame {...option} key={`IconFrame--${option.text.trim().replace(' ', '')}`} />)}
                        </Menu>
                    </Segment>
                    <Segment inverted className="Backstage--Participants">
                        <ParticipantList />
                    </Segment> 
                </div>
                
            </>
        </EventFrame>
    );
}

export default Backstage