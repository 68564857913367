const calcFromMap = (options: Array<any>, selected: string, defaultOption: string): any => {
    const optionMap = new Map(options)
    let select
    try {
        select = optionMap.get(selected) 
    } catch (err) {
        select = defaultOption
    }
    return select
}

export default calcFromMap
